// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';
@import '~nouislider/dist/nouislider.css';

#root {
  display: contents;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* for normal users chat*/

.hover-effect:hover {
  //opacity: 0.5;
  background-color: #f1faff;
}


.dropbtn {
  
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content span {
  color: black;
  padding: 12px 12px;
  text-decoration: none;
  display: block;
}

.dropdown-content span:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

/* for pagination*/
.datatable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #d9d9d9;
  color:#6b6c72;
}

.datatablethead{
  height:60px;
}
.thformname{
  background-color: #47596d;
  // border-right:1px solid #fff
}
.thcategory{
  background-color: #758391;
}
.theadbtn{
  color:#ffffff;
  border:none;
  background:none;
  font-size:16px;
  font-weight:700;
  &:hover{
    cursor: pointer;
  }
}

.tablerow{
  &:nth-child(even) {
    background-color: #f5f5f7;  
  }
  
}
.tabledata{
  border: 1px solid #ddd;
  text-align: center;
  padding: 10px 20px;
  width:50%;
}

.search{
  margin:10px;
  display:block;
  float:right;
  color:#6b6c72;
}

.inputlabel{
  border: 1px solid #d6d6d6;
  padding: 8px;
  width: 150px;
  vertical-align: middle;
}


.pointer {cursor: pointer;}


.form-select-anonymous {
  --bs-form-select-bg-img: url(
"data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%2378829d%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.775rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--bs-gray-700);
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid var(--bs-gray-300);
  border-radius: 0.475rem;
  box-shadow: false;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

.select2-container--bootstrap5 .select2-dropdown {
  border: 0;
  box-shadow:  "0px 0px 50px 0px rgba(82, 63, 105, 0.15)";
  border-radius: .475rem;
  padding: 1rem 0;
  background-color: #ffffff;
}


/* this css for sweet alert customization start*/
.custom-swal-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
}

.custom-swal-popup {
  background-color: #333; /* Customize the background color */
  color: #fff; /* Customize the text color */
  border-radius: 4px; /* Customize the border radius */
}

.custom-swal-icon {
  width: 20px; /* Customize the icon width */
  height: 20px; /* Customize the icon height */
  margin-right: 10px; /* Adjust the margin if needed */
}

/* this css for sweet alert customization end*/


.photo-grid {
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  // grid-gap: 10px;

  display: flex;
  flex-direction: row;
  gap: 10px;
} 

.photo-container {
  position: relative;
  overflow: hidden;
  border: 1px solid #ccc;
}

.photo {
  width: 100%;
  height: auto;
}

.menu-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ff0000; /* Red background color (adjust as needed) */
  color: #fff; /* White text color */
  border: none;
  padding: 5px;
  cursor: pointer;
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}

// .input-group {
//   position: relative;
//   display: table;
//   border-collapse: separate;
// }

.suffix {
  border-radius: 4px 0px 0px 4px;
}

.suffix .input-group-addon {
  border-left: 0;
  border-radius: 0px 4px 4px 0px;
}

